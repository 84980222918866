import React, { act, useEffect, useState, useRef } from "react";
import QrCode from "./QrCode";

const models = [
  {
    name: "tajin",
    link: "/model/cheep.glb",
    cameraOrbit: "90deg 90deg 14m",
    logo: "/logo.png",
    icon: "/favicon.png",
    src: "/model/sheep.glb",
    siteName: "AR Display",
  },
];

function App() {
  const [active, setActive] = useState(0);
  const modelViewer = useRef(null);
  
  useEffect(() => {
    if (!modelViewer.current) return;
    modelViewer.current.addEventListener("load", (p) => {
      modelViewer.current.cameraOrbit = "90deg 90deg 14m";
    }, { once: true }); 

    modelViewer.current.addEventListener("error", (p) => {
      console.log("Model error", p);
    }, { once: true });

    modelViewer.current.addEventListener("progress", (p) => {
      console.log("Model progress", p);
    }, { once: true });

    modelViewer.current.addEventListener(
      "before-render",
      (p) => {
        console.log("Model before-render", p);
      },
      { once: true }
    );
    setInterval(() => {
      let a = active < models.length-1 ? active + 1 : 0;
      setActive(a);
      modelViewer.current.src = models[a].src;
    }, 10000);
  }, []);

  const navigate = (index) => {
    setActive(index);
    modelViewer.current.src = models[index].src;
  }
  
  return (
    <div className="w-screen h-screen flex justify-stretch items-center flex-col bg-white gap-3">
      <div className="grow relative w-screen">
        <model-viewer
          ref={modelViewer}
          alt={models[active].name}
          src={models[active].src}
          environment-image="/moon_1k.hdr"
          shadow-intensity="1"
          camera-controls
          auto-rotate
        ></model-viewer>
      </div>
      {/* <div className="flex justify-center items-center gap-3 mb-3">
        <img
          onClick={() => navigate(0)}
          src="/slider/1.jpeg"
          alt="1"
          className={`w-16 h-16 rounded-md shadow-md cursor-pointer ${
            active === 0 ? "active" : ""
          }`}
        />
        <img
          onClick={() => navigate(1)}
          src="/slider/2.jpeg"
          alt="2"
          className={`w-16 h-16 rounded-md shadow-md cursor-pointer ${
            active === 1 ? "active" : ""
          }`}
        />
      </div> */}
      <div>
        <QrCode
          url={
            "https://art.ardisplay.io/model/model-sheep.glb/view"
          }
          icon={"/favicon.png"}
        />
      </div>
      <div className="w-screen flex justify-center items-center gap-3 bg-gray-100 py-3 shadow-lg">
        <span className="inline-block font-normal text-sm text-gray-600 mr-2">
          Powered by
        </span>
        <a
          rel="noreferrer"
          href={models[active].link}
          title={models[active].siteName}
          target="_blank"
        >
          <img
            className="inline-block h-8 w-auto"
            src={models[active].logo}
            alt="Client logo"
          />
        </a>
      </div>
    </div>
  );
}

export default App;
